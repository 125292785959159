<template>
  <div class="dse-secret" :class="computedClasses">
    <el-input
      ref="secretInput"
      v-model="currentValue"
      show-password
      :placeholder="placeholder ? (translatePlaceholder ? $t(placeholder) : placeholder) : ''"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :minlength="minlength"
      :clearable="clearable"
      autocomplete="off"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
    >
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    placeholder: {
      type: String,
      default: ''
    },
    translatePlaceholder: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: null
    },
    minlength: {
      type: Number,
      default: 0
    },
    clearable: Boolean
  },
  data() {
    return {
      currentValue: this.value,
      hasFocus: false
    };
  },
  computed: {
    computedClasses() {
      return { 'has-focus': this.hasFocus };
    }
  },
  watch: {
    value(value) {
      this.currentValue = this.value;
    }
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleFocus() {
      this.hasFocus = true;
    },
    handleBlur() {
      this.hasFocus = false;
    },
    focus() {
      this.$refs.secretInput.focus();
    }
  }
};
</script>

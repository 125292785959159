import MDinput from '@/components/MDinput';
import { validatorEmail, validatorRequire, validatorMinLength } from '@/utils/validators';
import Switch from '@/components/DSE/Switch';
import SecretInput from '@/components/DSE/SecretInput';
import Select from '@/components/DSE/Select';

export function getFormDefinition(vue) {
  return {
    id: {
      type: null,
      defaultValue: undefined
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true,
        caption: 'login.code'
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    email: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true,
        caption: 'common.email'
      },
      rules: [
        {
          validator: validatorRequire
        },
        {
          validator: validatorEmail
        }
      ]
    },
    kassaLoginCode: {
      type: SecretInput,
      props: {
        minlength: +vue.$store.getters['configuration/value']('password.policy.minimumLength'),
        maxlength: 64,
        caption: 'user.kassaLoginCode'
      },
      rules: [
        {
          validator: validatorRequire
        },
        {
          validator: validatorMinLength(vue.$store.getters['configuration/value']('password.policy.minimumLength'))
        }
      ]
    },
    languageID: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'navbar.language',
        required: true,
        valueField: 'id',
        labelField: 'description',
        loading: vue.$store.getters['language/isLoading'],
        options: vue.$store.getters['language/loadedItems']
      }
    },
    kassaEditAllowedYN: {
      type: Switch,
      props: {
        caption: 'user.kassaEditAllowedYN'
      }
    },
    isRestaurantManagerYN: {
      type: Switch,
      props: {
        caption: 'employee.is_restaurant_manager'
      }
    }
  };
}

export function createItemFromFormData(vue, form) {
  const user = {
    ...form,
    domainID: vue.$store.getters.domainID || null,
    roles: []
  };
  if (form.id) {
    user['id'] = form.id;
  }
  return user;
}

export function createFormDataFromItem(vue, user) {
  return {
    ...user
  };
}
